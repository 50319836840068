import React from "react";
import { SelectElement } from "react-hook-form-mui";
import { ProductDisposition } from "../../../../../graphql/__generated__/graphql";
import { toProperCase } from "../../../../../helpers";

export const MaterialDispositionElement: React.FC<{
	name?: string;
	label?: string;
	helperText?: string;
}> = ({ name, label, helperText }) => {
	const options: ProductDisposition[] = [
		ProductDisposition.Compost,
		ProductDisposition.Recycle,
		ProductDisposition.Landfill,
		ProductDisposition.Reuse,
		ProductDisposition.Donation
	];

	return (
		<SelectElement
			required
			name={name || "disposition"}
			label={label || "Material Disposition"}
			helperText={helperText || "Select the disposition of this material (where does it go?)"}
			options={options.map(option => ({
				id: option,
				label: toProperCase(option)
			}))}
		/>
	);
};