import React from "react";
import { Factory, CallSplit, Percent, Balance, Autorenew, TrendingUp, TrendingDown } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { Box, Text } from "grommet";
import { useEffect, useMemo, useState } from "react";
import { useWindowDimensions } from "../../hooks";
import { WasteReportingHook, weightDivertedToKgCo2e } from "../../features/admin/common";
import { formatNumber } from "../../helpers";
import { DashboardCard } from "../../features/admin/common/components/reporting/DashboardCard";
import { selectIsPartyMode, useAppSelector } from "../../store";
import { useLineItems } from "../../components";

export const TotalEmissionsAvoidedCard: React.FC<{
	report: WasteReportingHook;
}> = ({ report: data }) => {
	const { sizeIndex } = useWindowDimensions();

	const { totalWeightDivertedPounds } = useLineItems(data);

	const totalEmissionsReducedKgCo2e = useMemo(() => {
		return weightDivertedToKgCo2e(totalWeightDivertedPounds);
	}, [ totalWeightDivertedPounds ]);

	const value = useMemo(() => {
		if(totalEmissionsReducedKgCo2e > 1000) {
			return `${formatNumber(totalEmissionsReducedKgCo2e / 1000, 2)}`;
		}

		return `${formatNumber(totalEmissionsReducedKgCo2e, 2)}`;
	}, [ data ]);

	const subject = useMemo(() => {
		if(totalEmissionsReducedKgCo2e > 1000) {
			return "tonne(s) of CO2e";
		}

		return "kgCO2e";
	}, [ data ]);

	return (
		<DashboardCard
			icon={<Factory fontSize={sizeIndex <= 2 ? undefined : "large"} />}
			title="GHG's Avoided"
			value={value}
		>
			<Box direction="row" justify="end" align="end" flex>
				<Typography
					fontWeight="bold"
					textAlign="end"
					fontSize={sizeIndex <= 2 ? "small" : undefined}
				>
					{subject}
				</Typography>
			</Box>
		</DashboardCard>
	);
};

interface TotalWasteDivertedCardProps {
	wasteDivertedLabel: string;
}

export const TotalWasteDivertedCard: React.FC<TotalWasteDivertedCardProps> = (props) => {
	const { sizeIndex } = useWindowDimensions();
	return (
		<DashboardCard
			icon={<CallSplit fontSize={sizeIndex <= 2 ? undefined : "large"} />}
			title="Total Waste Diverted"
			value={props.wasteDivertedLabel}
		/>
	);
};

export const DiversionRateCard: React.FC<{
	report: WasteReportingHook;
}> = ({ report }) => {
	const { sizeIndex } = useWindowDimensions();
	const isPartyMode = useAppSelector(selectIsPartyMode);
	const [ showContaminationRate, setShowContaminationRate ] = useState<boolean>(false);

	const diversionGoal = useMemo(() => {
		return report.targetDiversionRate || 60;
	}, [ report ]);

	const diversionRate = useMemo(() => {
		if(!report.totalWeightGeneratedPounds) return 0;
		if(!report.totalWeightDivertedPounds) return 0;

		const rate = (report.totalWeightDivertedPounds / report.totalWeightGeneratedPounds) * 100;
		return Number(rate.toFixed(2));
	}, [ report ]);

	const diversionDiff = useMemo(() => {
		return (Number(diversionRate) - diversionGoal);
	}, [ diversionRate, diversionGoal ]);

	const diversionRateFormatted = useMemo(() => {
		if(isPartyMode) {
			if(diversionRate >= 90) {
				return `A (${formatNumber(diversionRate, 2)}%)`;
			}
			if(diversionRate >= 80) {
				return `B (${formatNumber(diversionRate, 2)}%)`;
			}
			if(diversionRate >= 70) {
				return `C (${formatNumber(diversionRate, 2)}%)`;
			}
			if(diversionRate >= 60) {
				return `D (${formatNumber(diversionRate, 2)}%)`;
			}
		}

		return diversionRate + "%";
	}, [ diversionRate ]);

	const contaminationRateFormatted = useMemo(() => {
		return "17.25%";
	}, []);

	const isDemoEnabled = localStorage.getItem("ENABLE_DEMO") === "true";

	return (
		<DashboardCard
			icon={<Percent fontSize={sizeIndex <= 2 ? undefined : "large"} />}
			title={showContaminationRate ? "Contamination Rate" : "Diversion Rate"}
			value={showContaminationRate ? contaminationRateFormatted : diversionRateFormatted}
			className={isPartyMode ? "btn-grad" : ""}
		>
			<Box direction="row" justify="between" align="end" flex>
				{isDemoEnabled && (
					<IconButton onClick={() => setShowContaminationRate(!showContaminationRate)}>
						<Autorenew fontSize="small" />
					</IconButton>
				)}
				{!showContaminationRate && (
					<Box align="end" justify="center" direction="row" gap="xsmall">
						<Box justify="center">
							{Number(diversionDiff) > 0
								? (
									<TrendingUp
										color="success"
										fontSize="small"
										style={{ marginBottom: "2px" }}
									/>
								)
								: (
									<TrendingDown
										color="error"
										fontSize="small"
										style={{ marginBottom: "2px" }}
									/>
								)
							}
						</Box>
						<Box justify="center">
							<Text
								size={sizeIndex <= 2 ? "small" : undefined}
								weight="bold"
								color={isPartyMode ? "white" : undefined}
							>
								{Math.abs(diversionDiff).toFixed(2)}% ({diversionGoal}%)
							</Text>
						</Box>
					</Box>
				)}
			</Box>
		</DashboardCard>
	);
};

export const WasteDiversionComparisonCard: React.FC<{
	report: WasteReportingHook;
}> = ({ report }) => {

	const isPartyMode = useAppSelector(selectIsPartyMode);
	const [ selected, setSelected ] = useState<number>(0);

	const comparisons = [
		{
			subject: "Blue Whale(s)",
			weight: 300000
		},
		{
			subject: "T-Rex(s)",
			weight: 12000
		},
		{
			subject: "Rhinos(s)",
			weight: 5000
		},
		{
			subject: "Grizzly Bear(s)",
			weight: 600
		}
	];

	const { totalWeightDivertedPounds } = useLineItems(report);

	useEffect(() => {
		const options = [ ...comparisons.filter(c => c.weight < totalWeightDivertedPounds), comparisons[ 0 ] ].sort((a, b) => {
			return b.weight - a.weight;
		});

		if(options.length === 0) {
			const selected = comparisons.find(c => {
				c.weight === Math.max(...comparisons.map(c => c.weight));
			});

			if(selected) {
				setSelected(comparisons.indexOf(selected));
			}
		}
		else {
			setSelected(comparisons.indexOf(options[ 0 ]));
		}
	}, [ totalWeightDivertedPounds ]);

	const subject = useMemo(() => {
		return comparisons[ selected ].subject;
	}, [ selected ]);

	const count = useMemo(() => {
		const comparison = comparisons[ selected ];
		const weight = comparison.weight;
		const count = Math.floor(totalWeightDivertedPounds / weight);

		return count;
	}, [ selected, totalWeightDivertedPounds ]);

	function toggleIndex(): void {
		setSelected((selected + 1) % comparisons.length);
	}

	const { sizeIndex } = useWindowDimensions();

	return (
		<DashboardCard
			icon={<Balance sx={{ color: isPartyMode ? "white" : undefined }} fontSize={sizeIndex <= 2 ? undefined : "large"} />}
			title="Equivalent To"
			value={String(count)}
			className={isPartyMode ? "btn-grad" : ""}
		>
			<Box direction="row" justify="between" align="end" flex>
				<IconButton onClick={toggleIndex}>
					<Autorenew fontSize="small" />
				</IconButton>
				<Typography
					fontWeight="bold"
					textAlign="end"
					color={isPartyMode ? "white" : undefined}
					fontSize={sizeIndex <= 2 ? "small" : undefined}
				>
					{subject}
				</Typography>
			</Box>
		</DashboardCard>
	);
};