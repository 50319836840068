import { gql } from "../../../__generated__";

export const FindPartners = gql(/* GraphQL */ `
	query FindPartners {
		FindPartners {
			id
			name
		}
	}
`);

export const FindInstancePartners = gql(/* GraphQL */ `
	query FindInstancePartners($instanceId: String!) {
		FindInstancePartners(
			instanceId: $instanceId
		) {
			id
			name
		}
	}
`);