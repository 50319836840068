import React, { Fragment, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { push } from "redux-first-history";
import { CreateWasteAudit, FindWasteAudit } from "../../../../../graphql";
import { useInstance } from "../../../../../hooks";
import { useAppDispatch } from "../../../../../store";
import { WasteAuditFormController, WasteAuditFormState } from "./WasteAuditFormController";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { Assessment } from "@mui/icons-material";

export const CreateWasteAuditButton: React.FC<{
	title?: string;
}> = ({ title }) => {
	const dispatch = useAppDispatch();
	const { instance } = useInstance();
	const [ isCreating, setIsCreating ] = useState(false);

	const formContext = useForm<WasteAuditFormState>({
		defaultValues: {
			name: "",
			partnerId: "",
			totalSteps: 3,
			currentStepIndex: 0,
			configType: "floor",
			rooms: [],
			floors: [],
			containers: []
		}
	});

	const [
		create,
		{ loading, data }
	] = useMutation(CreateWasteAudit, {
		refetchQueries: [ FindWasteAudit ]
	});

	useEffect(() => {
		if(data?.CreateWasteAudit.id) {
			dispatch(push(`/admin/audits/${data.CreateWasteAudit.id}`));
		}
	}, [ data, dispatch ]);

	function handleSubmit() {
		const { name, partnerId } = formContext.getValues();
		if(!name || !instance || !partnerId) return;

		create({
			variables: {
				name,
				partnerId: partnerId,
				instanceId: instance.id
			}
		});
	}

	return (
		<Fragment>
			{isCreating && (
				<WasteAuditFormController
					onSubmit={handleSubmit}
					formContext={formContext}
					onClose={() => setIsCreating(false)}
				/>
			)}
			<Button
				color="primary"
				variant="contained"
				startIcon={(
					<Assessment />
				)}
				onClick={() => setIsCreating(true)}
			>
				{title || "Create New"}
			</Button>
		</Fragment>
	);
};