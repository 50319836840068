import { gql } from "../../../__generated__";

export const FindWasteAudit = gql(/* GraphQL */ `
	query FindWasteAudit(
		$name: iStringFilters
		$instance: FindInstanceDTO
	) {
		FindWasteAudit(
			name: $name
			instance: $instance
		) {
			...WasteAudit_HeaderFragment	
		}
	}
`);

export const FetchWasteAudit = gql(/* GraphQL */ `
	query FetchWasteAudit(
		$auditId: String!
	) {
		FetchWasteAudit(
			auditId: $auditId
		) {
			...WasteAudit_HeaderFragment
			...WasteAudit_LineItemsFragment
			...WasteAudit_CollectionsFragment
		}
	}
`);