/* eslint-disable no-shadow */
import React, { useMemo } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const RADIAN = Math.PI / 180;
const cx = 150;
const cy = 200;
const iR = 50;
const oR = 100;

const needle = (value: any, data: any, cx: number, cy: number, iR: number, oR: number, color: string | undefined) => {
	let total = 0;
	data.forEach((v: any) => {
		total += v.value;
	});
	const ang = 180.0 * (1 - value / total);
	const length = (iR + 2 * oR) / 3;
	const sin = Math.sin(-RADIAN * ang);
	const cos = Math.cos(-RADIAN * ang);
	const r = 5;
	const x0 = cx + 5;
	const y0 = cy + 5;
	const xba = x0 + r * sin;
	const yba = y0 - r * cos;
	const xbb = x0 - r * sin;
	const ybb = y0 + r * cos;
	const xp = x0 + length * cos;
	const yp = y0 + length * sin;

	return [
		<circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
		<path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
	];
};

export const ContaminationRateChart: React.FC<{
	contaminationRate: number;
	stoplightConfig: [ number, number ];
}> = ({ contaminationRate, stoplightConfig }) => {
	const data = useMemo(() => {
		return [
			{ name: "Good", value: stoplightConfig[ 0 ], color: "green" },
			{ name: "Warning", value: stoplightConfig[ 1 ], color: "yellow" },
			{ name: "Contaminated", value: 100 - stoplightConfig[ 0 ] - stoplightConfig[ 1 ], color: "red" },
		];
	}, [ stoplightConfig ]);
	return (
		<ResponsiveContainer>
			<PieChart>
				<Pie
					dataKey="value"
					startAngle={180}
					endAngle={0}
					data={data}
					cx={cx}
					cy={cy}
					innerRadius={iR}
					outerRadius={oR}
					fill="#8884d8"
					stroke="none"
				>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={entry.color} />
					))}
				</Pie>
				{needle(contaminationRate, data, cx, cy, iR, oR, "#d0d000")}
			</PieChart>
		</ResponsiveContainer>
	);
};