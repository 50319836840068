import { useQuery } from "@apollo/client";
import { FetchWasteAudit, FindWasteAudit } from "../../../../graphql";

export function useWasteAudit(auditId: string) {
	const { data, loading } = useQuery(FetchWasteAudit, {
		variables: { auditId },
		skip: !auditId
	});

	return {
		audit: data?.FetchWasteAudit || null,
		loading
	};
}

export function useWasteAudits(
	instanceId: string,
	name?: string
) {
	const { data, loading } = useQuery(FindWasteAudit, {
		variables: {
			instance: { id: { equals: instanceId } },
			name: name ? { ilike: name } : undefined
		},
		skip: !instanceId
	});

	return {
		loading,
		audits: data?.FindWasteAudit || []
	};
}