import { Box, Grid } from "grommet";
import { ChartContainer, DiversionRateCard, FilterContainer, TotalEmissionsAvoidedCard, WasteDiversionComparisonCard } from "../../../../hackathon/components";
import { useWasteDiversion } from "../../../../hackathon/hooks";
import React, { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { Button, Typography } from "@mui/material";
import { CheeseburgerEquivalent, DialogWithActivatorButton, PizzaEquivalent, TotalWeightDivertedCard, ViewContainer } from "../../../../components";
import { DesktopOnlyGuard } from "../../../../guards";
import { FetchWasteReportQueryVariables, InstanceFeature } from "../../../../graphql/__generated__/graphql";
import { useQuery } from "@apollo/client";
import { useFeature } from "../../../instance/hooks/useFeature";
import { useInstance } from "../../../../hooks";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import type { Container, ISourceOptions } from "@tsparticles/engine";
import { selectIsPartyMode, useAppDispatch, useAppSelector } from "../../../../store";
import "./party.css";
import { FetchWasteReport } from "../../../../graphql";
import moment, { Moment } from "moment-timezone";
import { selectPartnerFilter, selectTimePeriodCustom, selectTimePeriodFilter, selectWasteTypeFilter } from "../../../../store/reporting";
import { push } from "redux-first-history";

export interface LineItemLike {
	__typename?: "WasteReportLineItem" | undefined;
	date: any;
	material?: { id: string; name: string; groupName: string; } | null;
	annualized: boolean;
	totalWeightReusedPounds: number;
	totalWeightRecycledPounds: number;
	totalWeightDonatedPounds: number;
	totalWeightCompostedPounds: number;
	totalWeightLandfilledPounds: number;
	totalWeightIncineratedPounds: number;
	totalWeightDivertedPounds: number;
	totalWeightGeneratedPounds: number;
}

function enumerateDaysBetween(startDate: Moment | null, endDate: Moment | null) {
	const begin = startDate || moment();
	const end = endDate || moment();

	const cursor = begin.clone();
	const dates: Moment[] = [];
	while(cursor.isBefore(end, "day")) {
		dates.push(cursor.clone());
		cursor.add(1, "day");
	}

	return dates;
}

export function enumerateLineItems(
	lineItems: LineItemLike[],
	startingDate: Moment | null,
	endingDate: Moment | null
): LineItemLike[] {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	///@ts-ignore
	return lineItems.flatMap((item: LineItemLike) => {
		if(!item.annualized) return {
			__typename: "WasteReportLineItem",
			date: item.date,
			annualized: false,
			material: item.material,
			totalWeightDivertedPounds: item.totalWeightDivertedPounds,
			totalWeightGeneratedPounds: item.totalWeightGeneratedPounds
		};

		const dates = enumerateDaysBetween(
			startingDate ? moment(startingDate) : null,
			endingDate ? moment(endingDate) : null
		);

		return dates.map(moment => {
			return {
				__typename: "WasteReportLineItem",
				annualized: true,
				date: moment.toISOString(),
				material: item.material,
				totalWeightDivertedPounds: item.totalWeightDivertedPounds,
				totalWeightGeneratedPounds: item.totalWeightGeneratedPounds
			};
		});
	});
}

export function sumLineItems(lineItems: LineItemLike[]) {
	return lineItems.reduce((acc, item) => {
		return {
			totalWeightDivertedPounds: acc.totalWeightDivertedPounds + item.totalWeightDivertedPounds,
			totalWeightGeneratedPounds: acc.totalWeightGeneratedPounds + item.totalWeightGeneratedPounds
		};
	}, { totalWeightDivertedPounds: 0, totalWeightGeneratedPounds: 0 });
}

export function useWasteReporting(
	instanceId: string,
	filters: Omit<FetchWasteReportQueryVariables, "instanceId">
) {
	const { data, loading, refetch } = useQuery(FetchWasteReport, {
		skip: !instanceId,
		variables: {
			...filters,
			instanceId
		}
	});

	useEffect(() => {
		const timer = setInterval(() => {
			refetch({
				...filters,
				instanceId
			});
		}, 1000 * 60);

		return () => {
			clearInterval(timer);
		};
	}, [ filters, instanceId, refetch ]);

	const startingDate = useMemo(() => {
		return filters.date?.isAfter || null;
	}, [ filters ]);

	const endingDate = useMemo(() => {
		return filters.date?.isBefore || null;
	}, [ filters ]);

	const lineItems = useMemo(() => {
		const incoming = data?.FetchWasteReport?.lineItems || [];
		return enumerateLineItems(incoming, startingDate, endingDate);
	}, [ data, endingDate, startingDate ]);

	return {
		loading,
		...data?.FetchWasteReport,
		lineItems
	};
}

export type WasteReportingHook = ReturnType<typeof useWasteReporting>;

export function useWasteReportingWithDemoGuard(
	instanceId: string,
	filters: Omit<FetchWasteReportQueryVariables, "instanceId">
) {
	const isDemoEnabled = useFeature(InstanceFeature.WasteDiversionDemoEnabled);

	const wasteReporting = useWasteReporting(instanceId, filters);
	const wasteReportingDemo = wrapper(useWasteDiversion(true));

	return (isDemoEnabled)
		? wasteReportingDemo
		: wasteReporting;
}

export function wrapper(hook: ReturnType<typeof useWasteDiversion>): WasteReportingHook {
	return {
		loading: false,
		targetDiversionRate: 60,
		totalWeightReusedPounds: 0,
		totalWeightRecycledPounds: 0,
		totalWeightCompostedPounds: 0,
		totalWeightDivertedPounds: hook.wasteDiverted,
		totalWeightLandfilledPounds: hook.wasteLandfilled,
		totalWeightIncineratedPounds: 0,
		totalWeightGeneratedPounds: hook.wasteGenerated,
		lineItems: hook.filteredOrders.map(order => {
			return {
				__typename: "WasteReportLineItem",
				date: order.date,
				annualized: false,
				totalWeightDonatedPounds: 0,
				totalWeightCompostedPounds: 0,
				totalWeightIncineratedPounds: 0,
				totalWeightLandfilledPounds: 0,
				totalWeightRecycledPounds: 0,
				totalWeightReusedPounds: 0,
				totalWeightDivertedPounds: order.weightDiverted,
				totalWeightGeneratedPounds: order.weightGenerated,
			};
		})
	};
}

export const PartyModeController: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	const [ init, setInit ] = useState(false);

	// this should be run only once per application lifetime
	useEffect(() => {
		initParticlesEngine(async (engine) => {
			// you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
			// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
			// starting from v2 you can add only the features you need reducing the bundle size
			//await loadAll(engine);
			//await loadFull(engine);
			await loadFull(engine);
			//await loadBasic(engine);
		}).then(() => {
			setInit(true);
		});
	}, []);

	const particlesLoaded = (container: Container) => {
		console.log(container);
	};

	const options: ISourceOptions = useMemo(
		() => ({
			fullScreen: {
				enable: true,
				zIndex: 0
			},
			background: {
				color: {
					value: "#0d47a1",
				},
			},
			fpsLimit: 120,
			interactivity: {
				events: {
					onClick: {
						enable: true,
						mode: "push",
					},
					onHover: {
						enable: true,
						mode: "repulse",
					},
				},
				modes: {
					push: {
						quantity: 4,
					},
					repulse: {
						distance: 200,
						duration: 0.4,
					},
				},
			},
			particles: {
				color: {
					value: "#ffffff",
				},
				links: {
					color: "#ffffff",
					distance: 150,
					enable: true,
					opacity: 0.5,
					width: 1,
				},
				move: {
					direction: "none",
					enable: true,
					outModes: {
						default: "bounce",
					},
					random: false,
					speed: 6,
					straight: false,
				},
				number: {
					density: {
						enable: true,
					},
					value: 80,
				},
				opacity: {
					value: 0.5,
				},
				shape: {
					type: "circle",
				},
				size: {
					value: { min: 1, max: 5 },
				},
			},
			detectRetina: true,
		}),
		[],
	);

	if(init) {
		return (
			<Fragment>
				<Box style={{ zIndex: 1 }}>
					{children}
				</Box>
				<Particles
					id="tsparticles"
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					///@ts-ignore
					particlesLoaded={particlesLoaded}
					options={options}
				/>
			</Fragment>
		);
	}

	return <></>;
};

export function useFilteredDate() {
	const timePeriodFilter = useAppSelector(selectTimePeriodFilter);
	const timePeriodCustom = useAppSelector(selectTimePeriodCustom);

	const startingDate = useMemo(() => {
		switch(timePeriodFilter) {
			case "TODAY": {
				return moment().startOf("day");
			}
			case "YESTERDAY": {
				return moment().subtract(1, "day").startOf("day");
			}
			case "LAST_3_MONTHS": {
				return moment().subtract(2, "months").startOf("month");
			}
			case "LAST_6_MONTHS": {
				return moment().subtract(5, "months").startOf("month");
			}
			case "LAST_MONTH": {
				return moment().subtract(1, "month").startOf("month");
			}
			case "LAST_YEAR": {
				return moment().subtract(1, "year").startOf("year");
			}
			case "THIS_MONTH": {
				return moment().startOf("month");
			}
			case "YTD": {
				return moment().startOf("year");
			}
			case "CUSTOM": {
				return moment(timePeriodCustom[ 0 ]).startOf("day");
			}
			default: {
				return moment().subtract(2, "years").startOf("year");
			}
		}
	}, [ timePeriodFilter, timePeriodCustom ]);

	const endingDate = useMemo(() => {
		switch(timePeriodFilter) {
			case "TODAY": {
				return moment().endOf("day");
			}
			case "YESTERDAY": {
				return moment().subtract(1, "day").endOf("day");
			}
			case "LAST_3_MONTHS": {
				return moment();
			}
			case "LAST_6_MONTHS": {
				return moment();
			}
			case "LAST_MONTH": {
				return moment().subtract(1, "month").endOf("month");
			}
			case "LAST_YEAR": {
				return moment().subtract(1, "year").endOf("year");
			}
			case "THIS_MONTH": {
				return moment();
			}
			case "YTD": {
				return moment();
			}
			case "CUSTOM": {
				return moment(timePeriodCustom[ 1 ]).endOf("day");
			}
			default: {
				return moment();
			}
		}
	}, [ timePeriodFilter, timePeriodCustom ]);

	return {
		startingDate,
		endingDate
	};
}

export const WasteReportingDashboardView: React.FC = () => {
	const { instance } = useInstance();
	const dispatch = useAppDispatch();
	const { startingDate, endingDate } = useFilteredDate();

	const partnerFilter = useAppSelector(selectPartnerFilter);
	const wasteTypeFilter = useAppSelector(selectWasteTypeFilter);
	const wasteReportHook = useWasteReportingWithDemoGuard(
		instance?.id || "",
		{
			date: {
				isAfter: startingDate.toISOString(),
				isBefore: endingDate.toISOString()
			},
			material: wasteTypeFilter.length === 0 ? undefined : {
				id: { in: wasteTypeFilter }
			},
			partner: partnerFilter.length === 0 ? undefined : {
				id: { in: partnerFilter }
			}
		}
	);

	const isPartyMode = useAppSelector(selectIsPartyMode);

	return (
		<ViewContainer>
			<DesktopOnlyGuard
				additionalContent={(
					<Box flex gap="medium">
						<Typography>
							Our waste dashboard works best on desktop. Have waste documents or receipts to upload? Click below to get started!
						</Typography>
						<Button
							fullWidth
							color="primary"
							variant="contained"
							onClick={() => {
								dispatch(push("/admin/waste-reporting/upload"));
							}}
						>
							Upload Documents
						</Button>
					</Box>
				)}
			>
				<Box gap="small" flex>
					<Box direction="row" gap="small" flex>
						<Box gap="small" flex height="100%">
							<Grid gap="small" columns={{ count: 4, size: "auto" }}>
								{isPartyMode
									? (
										<CheeseburgerEquivalent
											report={wasteReportHook}
										/>
									)
									: (
										<TotalWeightDivertedCard
											report={wasteReportHook}
										/>
									)}
								{isPartyMode
									? (
										<PizzaEquivalent
											report={wasteReportHook}
										/>
									)
									: (
										<TotalEmissionsAvoidedCard
											report={wasteReportHook}
										/>
									)
								}
								<WasteDiversionComparisonCard
									report={wasteReportHook}
								/>
								{isPartyMode
									? (
										<DiversionRateCard
											report={wasteReportHook}
										/>
									)
									: (
										<DiversionRateCard
											report={wasteReportHook}
										/>
									)}
							</Grid>
							<ChartContainer
								isMultifamily={true}
								report={wasteReportHook}
							/>
						</Box>
					</Box>
				</Box>
			</DesktopOnlyGuard>
		</ViewContainer>
	);
};

export const FilterWasteDashboardDialog: React.FC<{
	activatorButton: React.ReactNode;
}> = ({ activatorButton }) => {
	const [ forceClose, setForceClose ] = useState(false);

	useEffect(() => {
		if(forceClose) {
			setForceClose(false);
		}
	}, [ forceClose ]);

	return (
		<DialogWithActivatorButton
			title="Filter"
			forceClose={forceClose}
			activatorButton={activatorButton}
			actions={(
				<Box direction="row" justify="end">
					<Button onClick={() => setForceClose(true)}>
						Apply
					</Button>
				</Box>
			)}
		>
			<FilterContainer />
		</DialogWithActivatorButton>
	);
};