import { gql } from "../../../__generated__";

export const FetchWasteAuditIdentification = gql(/* GraphQL */ `
	query FindWasteAuditIdentificationByMediaId(
		$mediaId: String!
	) {
		FindWasteAuditIdentificationByMediaId(
			mediaId: $mediaId
		) {
			media { id name location contentUrl }
			identifications {
				id
				contours
				boundingBox
				isContamination
				classificationId
				classificationUri
				classificationName
				classificationScore
				classificationTop5 { idx, score, name}
				segmentation { size }
				segmentationArea
			}
		}
	}
`);