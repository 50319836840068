import React, { useEffect, useState } from "react";
import { Pagination, TabController, useMainContentHeight, ViewContainer } from "../../../../components";
import { Box, Grid, Heading, Spinner, Stack } from "grommet";
import { Badge, Button, Chip, Divider, IconButton, InputAdornment, LinearProgress, ListItemButton, ListItemSecondaryAction, ListItemText, Typography, useTheme } from "@mui/material";
import { Close, Edit, Fullscreen, Settings, SettingsSuggest, Visibility } from "@mui/icons-material";
import { useBuildings, useWasteAudit } from "../hooks";
import { useRequiredPathParam } from "../../../../hooks";

export const AuditRecommendationsButton: React.FC<{
	onClick?: () => void;
}> = ({ onClick }) => {
	const recommendations = [ "a", "b", "c" ];

	return (
		<Box align="center" justify="center">
			<Badge badgeContent={recommendations.length} color="primary">
				<Button
					size="small"
					color="primary"
					variant="outlined"
					onClick={onClick}
					startIcon={<SettingsSuggest />}
				>
					Advisor
				</Button>
			</Badge>
		</Box>
	);
};

export const AuditSettingsButton: React.FC<{
	onClick?: () => void;
}> = ({ onClick }) => {
	return (
		<Box align="center" justify="center">
			<Button
				size="small"
				color="primary"
				variant="outlined"
				startIcon={<Settings />}
				onClick={onClick}
			>
				Settings
			</Button>
		</Box>
	);
};

export const WasteAuditResultsContainer: React.FC = () => {
	return (
		<Box direction="row" gap="medium">
			<Box align="center" justify="center" gap="large">
				<Heading level="3" margin="none">
					Diversion Rate (%)
				</Heading>
				<Heading level="1" margin="none">
					20%
				</Heading>
			</Box>
		</Box>
	);
};

export const AuditDetailsV2View: React.FC = () => {
	const auditId = useRequiredPathParam("auditId", "/admin/audits");
	const { audit, loading } = useWasteAudit(auditId);

	const theme = useTheme();
	const height = useMainContentHeight();

	const [ activeTab, setActiveTab ] = useState("details");

	return (
		<ViewContainer gap="small">
			<Box gap="small">
				<Box direction="row" justify="between">
					<Box justify="center">
						<Heading
							level="3"
							margin="none"
						>
							Audit Details
						</Heading>
					</Box>
					<Box gap="small" direction="row" align="center" justify="end">
						<AuditRecommendationsButton
							onClick={() => {
								setActiveTab("recommendations");
							}}
						/>
						<AuditSettingsButton

						/>
					</Box>
				</Box>
				<Divider
					sx={{
						borderColor: theme.palette.primary.main
					}}
				/>
			</Box>
			<Grid columns={[ "2/3", "1/3" ]} height="100%">
				<Box gap="small" margin={{ right: "medium" }}>
					<TabController
						value={activeTab}
						onValueChange={setActiveTab}
						defaultValue="details"
						tabs={[
							{
								label: "Details",
								value: "details",
								component: <AuditDetailsTabContent auditId={auditId} />
							},
							{
								label: "Uploads",
								value: "uploads",
								component: <AuditUploadsTabContent auditId={auditId} />
							},
							{
								label: "Recommendations",
								value: "recommendations",
								component: <AuditRecommendationsTabContent auditId={auditId} />
							}
						]}
					/>
				</Box>
				<Box gap="medium" height="100%">
					<Box
						flex
						hoverIndicator
						onClick={noop}
						background="white" round elevation="medium" pad="small"
					>
						<Stack style={{ height: "100%", width: "100%" }}>
							<Box direction="row" justify="between" align="start">
								<Heading level="4" margin="none">
									Waste Generated
								</Heading>
								<IconButton sx={{ padding: 0 }} style={{ zIndex: 2 }}>
									<Fullscreen />
								</IconButton>
							</Box>
							<WasteGeneratedPieChart />

						</Stack>
					</Box>
					<Box
						flex
						hoverIndicator
						onClick={noop}
						background="white" round elevation="medium" pad="small"
					>
						<Stack style={{ height: "100%", width: "100%" }}>
							<Box direction="row" justify="between" align="start">
								<Heading level="4" margin="none">
									Waste Diverted
								</Heading>
								<IconButton style={{ zIndex: 2 }}>
									<Fullscreen />
								</IconButton>
							</Box>
							<WasteGeneratedPieChart />

						</Stack>
					</Box>
				</Box>
			</Grid>
		</ViewContainer>
	);
};

export const AuditDetailsTabContent: React.FC<{
	auditId: string;
}> = ({ auditId }) => {
	const { audit, loading } = useWasteAudit(auditId);
	const { buildings } = useBuildings();

	const formContext = useForm({
		defaultValues: {
			name: audit?.name,
			partnerId: audit?.partner?.id,
			createdAt: audit?.createdAt ? moment(audit.createdAt).format("MMMM Do YYYY") : "",
		}
	});

	useEffect(() => {
		formContext.reset({
			name: audit?.name,
			partnerId: audit?.partner?.id,
			createdAt: audit?.createdAt ? moment(audit.createdAt).format("MMMM Do YYYY") : "",
		});
	}, [ audit, formContext ]);

	const [ titleSaving, setTitleSaving ] = useState(false);
	useEffect(() => {
		if(titleSaving) {
			const timer = setTimeout(() => {
				setTitleSaving(false);
			}, 1000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [ titleSaving ]);

	const [ parnterSaving, setPartnerSaving ] = useState(false);

	useEffect(() => {
		if(parnterSaving) {
			const timer = setTimeout(() => {
				setPartnerSaving(false);
			}, 1000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [ parnterSaving ]);

	return (
		<FormContainer formContext={formContext}>
			<Box gap="medium">
				<TextFieldElement
					name="name"
					label="Name"
					InputProps={{
						onBlur: () => {
							setTitleSaving(true);
						},
						endAdornment: titleSaving && (
							<InputAdornment
								position="end"
								sx={{ mr: 2 }}
							>
								<Spinner size="small" />
							</InputAdornment>
						)
					}}
				/>
				<TextFieldElement
					name="createdAt"
					label="Created At"
					InputProps={{
						readOnly: true
					}}
				/>
				<SelectElement
					required
					fullWidth
					name="partnerId"
					label="Location"
					options={buildings.map(building => ({
						id: building.id,
						label: building.name
					}))}
					InputProps={{
						onChange: () => {
							setPartnerSaving(true);
						},
						endAdornment: parnterSaving && (
							<InputAdornment
								position="end"
								sx={{ mr: 2 }}
							>
								<Spinner size="small" />
							</InputAdornment>
						)
					}}
				/>
			</Box>
		</FormContainer>
	);
};

export const AuditRecommendationsTabContent: React.FC<{
	auditId: string;
}> = ({ auditId }) => {
	const { audit, loading } = useWasteAudit(auditId);
	const [ recommendations, setRecommendations ] = useState([
		{ id: "1", name: "Cardboard Recycling", description: "It looks like you have cardboard present in your landfill waste stream. Cardboard can take up a considerable amount of space and lead to over spend. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
		{ id: "2", name: "Reduce Waste from Move Out", description: "Implement a move-in/move-out recycling and donation program to minimize waste generated from discarded furniture, electronics, and packing materials. This will significantly reduce landfill waste and help manage bulky item disposal." },
		{ id: "3", name: "Achieve LEED Credits by Enhancing Resident Engagement", description: "Encourage residents to actively participate in recycling and composting programs through educational campaigns and incentives. High resident participation can boost recycling rates, contributing to LEED certification under waste management categories​." }
		// { id: "2", name: "Cardboard Recycling", description: "It looks like you have a lot of cardboard in your landfill waste stream. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
		// { id: "3", name: "Cardboard Recycling", description: "It looks like you have a lot of cardboard in your landfill waste stream. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
		// { id: "4", name: "Cardboard Recycling", description: "It looks like you have a lot of cardboard in your landfill waste stream. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
		// { id: "5", name: "Cardboard Recycling", description: "It looks like you have a lot of cardboard in your landfill waste stream. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
		// { id: "6", name: "Cardboard Recycling", description: "It looks like you have a lot of cardboard in your landfill waste stream. Consider adding a cardboard recycling container to your location to reduce landfill waste and potentially reduce your waste spending." },
	]);

	const [ contaminationRecommendations, setContaminationRecommendations ] = useState([
		{ id: "1", name: "Styrofoam Contamination", description: "We found styrofoam present in your recycling stream. Styrofoam is not recyclable in Dallas, TX and can contaminate your recycling stream. Consider adding a styrofoam recycling container to your location to reduce contamination and improve recycling rates." },
		{ id: "2", name: "Contaminated Paper Products", description: "We found contaminated paper products in your recycling stream. While compostable, contaminated paper products like tissues, napkins and paper towels are not recyclable and can contaminate your recycling stream. Consider adding a compost container to your location to reduce contamination and improve recycling rates." },
	]);

	function dismissRecommendation(id: string): void {
		setRecommendations(contaminationRecommendations.filter(r => r.id !== id));
	}

	if(contaminationRecommendations.length === 0) {
		return (
			<Box height="small" align="center" justify="center" gap="small">
				<Typography variant="body2">
					no recommendations found
				</Typography>
			</Box>
		);
	}

	return (
		<Pagination pageSize={3}>
			{contaminationRecommendations.map((recommendation) => (
				<ListItemButton key={recommendation.id} divider style={{ height: "80px" }}>
					<ListItemText>
						<Typography fontWeight="bold" variant="body2" className="text-max-1-line">
							{recommendation.name}
						</Typography>
						<Typography
							variant="caption"
							sx={{ maxWidth: "80%" }}
							className="text-max-2-lines"
						>
							{recommendation.description}
						</Typography>
					</ListItemText>
					<ListItemSecondaryAction>
						<Box justify="end" direction="row" gap="xsmall">
							<IconButton color="primary">
								<Visibility />
							</IconButton>
							<IconButton
								color="error"
								onClick={() => {
									dismissRecommendation(recommendation.id);
								}}
							>
								<Close />
							</IconButton>
						</Box>
					</ListItemSecondaryAction>
				</ListItemButton>
			))}
		</Pagination>
	);
};

export const AuditUploadsTabContent: React.FC<{
	auditId: string;
}> = ({ auditId }) => {
	const { audit, loading } = useWasteAudit(auditId);
	const dispatch = useAppDispatch();

	function handleSelect() {
		dispatch(push("/admin/inspector/f01d8aca-7f21-4a8f-ba31-913bd16fa021"));
	}

	if(loading) {
		return (
			<LinearProgress />
		);
	}

	if(audit?.collections?.length === 0 && !loading) {
		return (
			<Box height="small" align="center" justify="center" gap="small">
				<Typography variant="body2">
					no uploads found
				</Typography>
			</Box>
		);
	}

	return (
		<Box gap="small" flex>
			<Box align="start">
				<CreateWasteAuditCollectionDialog
					auditId={auditId}
					activatorButton={(
						<Button
							startIcon={(
								<Edit />
							)}
							size="small"
							variant="contained"
						>
							Upload
						</Button>
					)}
				/>
			</Box>
			<Pagination pageSize={4}>
				{(audit?.collections || []).map((collection) => (
					<ListItemButton key={collection.id} divider onClick={handleSelect}>
						<ListItemText>
							<Typography fontWeight="bold">
								{collection.metadata.containerName}
							</Typography>
							<Typography variant="caption">
								{moment(collection.createdAt).format("dddd MMMM Do, YYYY")}
							</Typography>
						</ListItemText>
						<ListItemSecondaryAction>
							<Box direction="row" gap="small" justify="end" align="center">
								{Number(collection.totalWeight) > 0 && (
									<Typography fontWeight="bold">
										{formatNumber(collection.totalWeight)} lb(s)
									</Typography>
								)}
								{collection.metadata.containerDisposition && (
									<Chip
										color="info"
										label={collection.metadata.containerDisposition}
									/>
								)}
								{collection.identificationCompleted && (
									<Chip
										color="success"
										label="Processed"
									/>
								)}
							</Box>
						</ListItemSecondaryAction>
					</ListItemButton>
				))}
			</Pagination>
		</Box>
	);
};

import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from "recharts";
import moment from "moment";
import { formatNumber, noop } from "../../../../helpers";
import { CreateWasteAuditCollectionDialog } from "./AuditDetails";
import { FormContainer, SelectElement, TextFieldElement, useForm } from "react-hook-form-mui";
import { useAppDispatch } from "../../../../store";
import { push } from "redux-first-history";


const data = [
	{ name: "Plastic", value: 46 },
	{ name: "Paper", value: 29 },
	{ name: "Metal (Cans)", value: 23 },
	{ name: "Mixed Waste", value: 2 }
];

const COLORS = [ "#0088FE", "#00C49F", "#FFBB28", "#FF8042" ];

const RADIAN = Math.PI / 180;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
///@ts-ignore
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

export const WasteGeneratedPieChart: React.FC = () => {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<PieChart>
				<Legend
					align="left"
					verticalAlign="middle"
					layout="vertical"
				/>
				<Pie
					data={data}
					cx="50%"
					cy="50%"
					labelLine={false}
					label={renderCustomizedLabel}
					outerRadius={80}
					fill="#8884d8"
					dataKey="value"
				>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[ index % COLORS.length ]} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
};