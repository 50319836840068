import { gql } from "../../../__generated__";

export const CreateWasteAuditCollection = gql(/* GraphQL */ `
	mutation CreateWasteAuditCollection(
		$auditId: String!
		$metadata: WasteAuditMetadataInputDTO!
		$media: [CreateMediaDTO!]!
		$totalWeight: Float!
		$assignmentId: String
	) {
		CreateWasteAuditCollection(
			auditId: $auditId
			metadata: $metadata
			media: $media
			totalWeight: $totalWeight
			assignmentId: $assignmentId
		) {
			id
		}
	}
`);