import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DialogWithClose, ViewContainer } from "../../../../components";
import { useRequiredPathParam, useWindowDimensions } from "../../../../hooks";
import { useQuery } from "@apollo/client";
import { FetchWasteAuditIdentification } from "../../../../graphql/documents/reporting/queries/WasteAuditIdentification";
import { Box, Grid, Heading, Meter, Spinner } from "grommet";
import { FormContainer, SelectElement, TextFieldElement, ToggleButtonGroupElement, useForm } from "react-hook-form-mui";
import { ResultOf } from "@graphql-typed-document-node/core";
import { Button, IconButton, InputAdornment, ListItem, ListItemText, Slider, Typography } from "@mui/material";
import { formatNumber } from "../../../../helpers";
import { Edit } from "@mui/icons-material";
import { ContaminationRateChart } from "../components/ContaminationRateChart";

export type MaskData = {
	binaryMask: Uint8Array;
	originalWidth: number;
	originalHeight: number;
};

export const UpdateWeightDialog: React.FC<{
	weight: number;
	material: string;
	onUpdateWeight: (weight: number) => void;
}> = ({ weight, material, onUpdateWeight }) => {
	const formContext = useForm({
		defaultValues: {
			weight,
			material
		}
	});

	function handleSubmit() {
		const values = formContext.getValues();
		onUpdateWeight(values.weight);
	}

	return (
		<DialogWithClose
			title="Update Weight"
			onClose={() => onUpdateWeight(weight)}
			content={(
				<Box>
					<FormContainer formContext={formContext}>
						<Box gap="medium">
							<TextFieldElement
								name="material"
								label="Material"
								disabled
							/>
							<TextFieldElement
								name="weight"
								label="Weight"
								type="number"
								validation={{
									required: "Weight is required",
									min: 0,
									pattern: /^[0-9]+(\.[0-9]+)?$/
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											lb(s)
										</InputAdornment>
									)
								}}
							/>
						</Box>
					</FormContainer>
				</Box>
			)}
			actions={(
				<Box direction="row" justify="between">
					<Button color="error" variant="outlined" onClick={() => onUpdateWeight(weight)}>
						Cancel
					</Button>
					<Button color="primary" variant="contained" onClick={formContext.handleSubmit(handleSubmit)}>
						Update
					</Button>
				</Box>
			)}
		/>
	);
};

export const InspectorView: React.FC = () => {
	const containerRef = useRef<HTMLDivElement>(null);
	const mediaId = useRequiredPathParam("mediaId", "/admin/dashboard");

	const { data, loading } = useQuery(FetchWasteAuditIdentification, {
		variables: {
			mediaId
		},
		skip: !mediaId
	});

	const identifications = useMemo(() => {
		return data?.FindWasteAuditIdentificationByMediaId?.identifications || [];
	}, [ data ]);

	const [ { width, height }, setDimensions ] = useState({ width: 0, height: 0 });

	const mediaUrl = useMemo(() => {
		return data?.FindWasteAuditIdentificationByMediaId?.media?.contentUrl || "";
	}, [ data ]);

	const image = useMemo(() => {
		const image = new Image();
		image.src = mediaUrl;

		return image;
	}, [ mediaUrl ]);

	useEffect(() => {
		const updateSize = () => {
			if(containerRef.current) {
				const { width, height } = containerRef.current.getBoundingClientRect();
				setDimensions({ width, height });
			}
		};

		// Use requestAnimationFrame to ensure layout is complete before getting the size
		const handleInitialSize = () => {
			requestAnimationFrame(updateSize);
		};

		// Call the function immediately to capture the initial size
		handleInitialSize();

		const resizeObserver = new ResizeObserver((entries) => {
			if(!entries || entries.length === 0) return;

			// Extract the width and height of the container
			const { width, height } = entries[ 0 ].contentRect;
			setDimensions({ width, height });
		});

		const current = containerRef.current;

		if(current) {
			resizeObserver.observe(containerRef.current);
		}

		// Clean up observer when component unmounts
		return () => {
			if(current) {
				resizeObserver.unobserve(current);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Empty dependency array to only attach observer on mount

	const [ weights, setWeights ] = useState<Record<string, number>>({});

	const [ isUpdatingWeights, setIsUpdatingWeights ] = useState("");

	const materials = useMemo(() => {
		if(!identifications.length) return [];
		const [ height, width ] = identifications[ 0 ].segmentation.size;
		const reduced = identifications.map(identification => {
			return {
				class: identification.classificationName,
				score: identification.classificationScore,
				area: identification.segmentationArea,
				imageWidth: identification.segmentation.size[ 1 ],
				imageHeight: identification.segmentation.size[ 0 ],
			};
		}).reduce((acc, curr) => {
			const existing = acc.find(({ class: c }) => c === curr.class);
			if(existing) {
				existing.totalArea += curr.area;
				const index = acc.findIndex(({ class: c }) => c === curr.class);
				acc[ index ] = existing;
			}
			else {
				acc.push({
					class: curr.class,
					totalArea: curr.area
				});
			}

			return acc;
		}, [] as { class: string, totalArea: number; }[]);

		const totalGlobalArea = reduced.reduce((acc, curr) => acc + curr.totalArea, 0);
		const totalGlobalWeight = Object.values(weights).reduce((acc, curr) => acc + curr, 0);

		return reduced.map(({ class: name, totalArea }) => {
			const weight = weights[ name ] || 0;
			return {
				name,
				totalArea,
				totalWeight: weights[ name ] || 0,
				percentOfWeight: totalGlobalWeight === 0
					? 0
					: (weight / totalGlobalWeight) * 100,
				percentOfImage: (totalArea / (width * height)) * 100,
				percentOfSegmentation: (totalArea / totalGlobalArea) * 100
			};
		}).sort((a, b) => b.percentOfImage - a.percentOfImage);
	}, [ identifications, weights ]);

	const [ transparency, setTransparency ] = useState(0.0);
	const { mainContentHeight } = useWindowDimensions();
	const [ clickedMaskId, setClickedMaskId ] = useState("");

	function handleUpdateWeight(material: string, weight: number) {
		setWeights({
			...weights,
			[ material ]: weight
		});
	}

	const materials2 = useMemo(() => {
		return [
			{
				name: "Plastic",
				totalArea: 46,
				totalWeight: 0,
				percentOfWeight: 42,
				percentOfImage: 42,
				percentOfSegmentation: 42
			},
			{
				name: "Paper",
				totalArea: 29,
				totalWeight: 0,
				percentOfWeight: 31,
				percentOfImage: 31,
				percentOfSegmentation: 31
			},
			{
				name: "Metal (Cans)",
				totalArea: 23,
				totalWeight: 0,
				percentOfWeight: 23,
				percentOfImage: 23,
				percentOfSegmentation: 23
			},
			{
				name: "Mixed Waste",
				totalArea: 2,
				totalWeight: 0,
				percentOfWeight: 2,
				percentOfImage: 2,
				percentOfSegmentation: 2
			}
		];
	}, []);

	const formContext = useForm({
		defaultValues: {
			view: "composition" as "composition" | "contamination",
			floor: "Floor #1",
			location: "Office Location #1"
		}
	});

	const { view } = formContext.watch();

	const contaminationRate = useMemo(() => {
		const totalArea = materials.reduce((acc, curr) => acc + curr.totalArea, 0);
		const contaminationArea = identifications.filter(i => i.isContamination).reduce((acc, curr) => acc + curr.segmentationArea, 0);

		return (contaminationArea / totalArea) * 100;
	}, [ identifications, materials ]);

	return (
		<ViewContainer>
			{isUpdatingWeights && (
				<UpdateWeightDialog
					weight={weights[ isUpdatingWeights ] || 0}
					material={isUpdatingWeights}
					onUpdateWeight={(weight) => {
						handleUpdateWeight(isUpdatingWeights, weight);
						setIsUpdatingWeights("");
					}}
				/>
			)}
			<Grid gap="small" columns={[ "1/3", "2/3" ]} height={`${mainContentHeight - 50}px`}>
				<Box height="100%" overflow={{ vertical: "scroll" }}>
					{clickedMaskId
						? (
							<BoundingBoxCanvas
								image={image}
								onClose={() => setClickedMaskId("")}
								materials={materials.map(({ name }) => name)}
								className={identifications.find(i => i.id === clickedMaskId)?.classificationName || ""}
								classScore={identifications.find(i => i.id === clickedMaskId)?.classificationScore || 0}
								top5={identifications.find(i => i.id === clickedMaskId)?.classificationTop5 || []}
								bbox={(([ x, y, width, height ]) => {
									return {
										x,
										y,
										width,
										height
									};
								})(identifications.find(i => i.id === clickedMaskId)?.boundingBox || [ 0, 0, 0, 0 ])}
							/>
						)
						: (
							<FormContainer
								FormProps={{ style: { height: `${mainContentHeight - 50}px` } }}
								formContext={formContext}
							>
								<Box gap="medium" height="100%">
									<ToggleButtonGroupElement
										name="view"
										exclusive
										unselectable="on"
										fullWidth
										color="primary"
										options={[
											{ id: "composition", label: "Composition" },
											{ id: "contamination", label: "Contamination" }
										]}
									/>
									<Typography
										fontWeight="bold"
									>
										Attribution
									</Typography>
									<TextFieldElement
										name="location"
										label="Location"
									/>
									<TextFieldElement
										name="floor"
										label="Floor"
									/>
									{view === "contamination" && (
										<Fragment>
											<Typography
												fontWeight="bold"
												margin="small"
											>
												Contamination Rate
											</Typography>
											<Box align="center" justify="center" margin={{ top: "medium" }}>
												<Heading level={2} margin="none">
													{formatNumber(contaminationRate, 2)}%
												</Heading>
											</Box>
											<Box height="100%" align="center" justify="center">
												<ContaminationRateChart
													contaminationRate={contaminationRate}
													stoplightConfig={[ 10, 20 ]}
												/>
											</Box>
										</Fragment>
									)}
									{view === "composition" && (
										<Fragment>
											<Typography fontWeight="bold">Materials</Typography>
											<Box height="100%" overflow={{ vertical: "scroll" }}>
												{materials2.map(({ name, percentOfWeight, percentOfImage }) => (
													<ListItem key={name}>
														<Box>
															<Box direction="row" justify="between">
																<ListItemText>
																	<Typography fontWeight="bold">
																		{name}
																	</Typography>
																</ListItemText>
																<Typography textAlign="end">
																	{formatNumber(
																		materials.some(m => m.totalWeight)
																			? percentOfWeight
																			: percentOfImage
																		, 2)}%
																</Typography>
															</Box>
															<Box direction="row" align="center" gap="small">
																<Meter
																	value={
																		materials.some(m => m.totalWeight)
																			? percentOfWeight
																			: percentOfImage
																	}
																/>
																<Box align="center" justify="center">
																	<IconButton size="small" onClick={() => setIsUpdatingWeights(name)}>
																		<Edit />
																	</IconButton>
																</Box>
															</Box>
														</Box>
													</ListItem>
												))}
											</Box>
										</Fragment>
									)}
								</Box>
							</FormContainer>
						)}
				</Box>
				<Box height="100%" width="100%">
					<Box height="100%" align="center" justify="center" background="light-2" ref={containerRef}>
						{loading ? (
							<Spinner />
						) : (
							<SegmentationCanvas
								view={view}
								image={image}
								width={width}
								height={height}
								transparency={transparency}
								identifications={identifications}
								onClickedMask={setClickedMaskId}
							/>
						)}
					</Box>
					<Box height="50px" width="100%" justify="end">
						<Slider
							value={transparency}
							onChange={(_, value) => setTransparency(
								Array.isArray(value) ? value[ 0 ] : value
							)}
							aria-label="Transparency"
							step={0.05}
							min={0}
							max={1}
							valueLabelDisplay="on"
						/>
					</Box>
				</Box>

			</Grid>
		</ViewContainer>
	);
};

function updateAlphaInHsla(hslaColor: string, newAlpha: number): string {
	// Match and extract the hue, saturation, and lightness values using regex
	const hslRegex = /hsla?\((\d+),\s*(\d+)%,\s*(\d+)%.*\)/;
	const match = hslaColor.match(hslRegex);

	if(match) {
		const hue = match[ 1 ];
		const saturation = match[ 2 ];
		const lightness = match[ 3 ];

		// Return the new hsla color string with updated alpha
		return `hsla(${hue}, ${saturation}%, ${lightness}%, ${newAlpha})`;
	}
	else {
		// If the input is not in the correct format, return the original color
		console.warn("Invalid HSLA color format");
		return hslaColor;
	}
}

function generateDistinctColorsWithAlpha(numColors: number, alpha: number): Array<string> {
	const colors = [];
	const saturation = 70; // Keep saturation constant for vibrant colors
	const lightness = 50;  // Keep lightness constant for equal brightness

	for(let i = 0; i < numColors; i++) {
		// Evenly space the hue values
		const hue = Math.floor((i * 360) / numColors);
		colors.push(`hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`);
	}

	return colors;
}

export const BoundingBoxCanvas: React.FC<{
	onClose?: () => void;
	image: HTMLImageElement,
	materials: string[],
	className: string;
	classScore: number;
	top5: { name: string, score: number; }[];
	bbox: { x: number, y: number, width: number, height: number; };
}> = ({ image, bbox, className, classScore, onClose, materials }) => {
	const ref = useRef<HTMLCanvasElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const availableWidth = useMemo(() => {
		return containerRef.current?.clientWidth || 0;
	}, [ containerRef ]);

	const { scaledWidth, scaledHeight, scale } = useMemo(() => {
		// Calculate the scale factor to fit the image within the available width or maximum 300 pixels (height or width)
		const maxWidth = Math.min(availableWidth || 300, 300);
		const maxHeight = 300;

		const widthScale = maxWidth / image.width;
		const heightScale = maxHeight / image.height;

		const scale = Math.min(widthScale, heightScale);

		// Calculate the scaled width and height
		const width = image.width * scale;
		const height = image.height * scale;

		return { scaledWidth: width, scaledHeight: height, scale };
	}, [ availableWidth, image ]);

	const draw = useCallback((width: number, height: number, scale: number) => {
		if(!ref.current) return;
		const ctx = ref.current.getContext("2d");
		if(!ctx) return;

		const { x, y, width: boxWidth, height: boxHeight } = bbox;
		ctx.clearRect(0, 0, width, height);

		ctx.drawImage(
			image,        // image element
			x, y,       // source position (bounding box start)
			boxWidth, boxHeight, // source size (bounding box size)
			0, 0,       // destination position on canvas
			image.width * scale, image.height * scale // destination size on canvas (scaled)
		);
	}, [ bbox, image ]);

	useEffect(() => {
		if(image) {
			if(image.complete) {
				// If the image is already loaded, draw it immediately
				draw(scaledWidth, scaledHeight, scale);
			}
			else {
				// Listen for when the image is fully loaded, then draw it
				image.onload = () => {
					draw(scaledWidth, scaledHeight, scale);
				};
			}
		}
	}, [ image, draw, scaledWidth, scaledHeight, scale ]);

	const formContext = useForm({
		defaultValues: {
			class: className,
			wasEdited: false,
			score: `${formatNumber(classScore * 100, 2)}%`
		}
	});
	const { wasEdited } = formContext.watch();

	useEffect(() => {
		formContext.reset({
			class: className,
			wasEdited,
			score: `${formatNumber(classScore * 100, 2)}%`
		});
	}, [ className, classScore, formContext, wasEdited ]);

	return (
		<Box flex ref={containerRef} gap="medium">
			<FormContainer formContext={formContext}>
				<Box gap="medium">
					<Typography fontWeight="bold">
						Material Classification
					</Typography>
					<SelectElement
						name="class"
						label="Material"
						onChange={(event) => {
							formContext.setValue("wasEdited", true);
						}}
						options={materials.map(material => ({ id: material, label: material }))}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end" style={{ left: "-100px" }}>
									{wasEdited && "Edited"}
								</InputAdornment>
							)
						}}
					/>
					<TextFieldElement
						name="score"
						label="Confidence"
					/>
				</Box>
			</FormContainer>
			<Box flex justify="end" gap="medium">
				<Box align="center" justify="center" background="light-2">
					<canvas
						ref={ref}
						width={scaledWidth}
						height={scaledHeight}
						style={{
							width: `${scaledWidth}px`,
							height: `${scaledHeight}px`
						}}
					/>
				</Box>
				{onClose && (
					<Button color="primary" variant="contained" onClick={onClose}>
						Close
					</Button>
				)}
			</Box>
		</Box>
	);
};

export const SegmentationCanvas: React.FC<{
	view: "composition" | "contamination";
	width: number;
	height: number;
	transparency: number;
	image: HTMLImageElement;
	onClickedMask: (maskId: string) => void;
	identifications: ResultOf<typeof FetchWasteAuditIdentification>[ "FindWasteAuditIdentificationByMediaId" ][ "identifications" ];
}> = ({ view, width, height, image, identifications, transparency, onClickedMask }) => {
	const ref = useRef<HTMLCanvasElement>(null);
	const [ hoveredMaskId, setHoveredMaskId ] = useState("");

	const masks = useMemo(() => {
		const imageArea = width * height;
		return identifications.filter(identification => {
			return identification.segmentationArea / imageArea > 0.001 && identification.segmentationArea / imageArea < 0.70 && (view === "contamination" || identification.classificationScore > .60) && (view === "composition" || identification.isContamination);
		}).map((identification) => {
			return {
				id: identification.id,
				contours: douglasPeucker(identification.contours.map(([ x, y ]) => ({ x, y })), 0)
			};
		});
	}, [ height, identifications, view, width ]);

	const [ colors, setColors ] = useState<string[]>([]);

	const drawImage = useCallback((mouseX: number, mouseY: number) => {
		const canvas = ref.current;
		if(!canvas) return;
		const context = canvas.getContext("2d");

		if(context && image && image.complete) {
			const hRatio = width / image.width;
			const vRatio = height / image.height;
			const ratio = Math.min(hRatio, vRatio);
			const centerShift_x = (width - image.width * ratio) / 2;
			const centerShift_y = (height - image.height * ratio) / 2;
			context.clearRect(0, 0, width, height);
			context.drawImage(image, 0, 0, image.width, image.height,
				centerShift_x, centerShift_y, image.width * ratio, image.height * ratio);

			let localColors = colors;
			if(!colors.length) {
				localColors = generateDistinctColorsWithAlpha(masks.length, transparency);
				setColors(localColors);
			}

			const pointsScaledAndShifted = masks.map((mask) => {
				return mask.contours.map(({ x, y }) => ({
					id: mask.id,
					x: x * ratio + centerShift_x,
					y: y * ratio + centerShift_y
				}));
			});

			for(let i = 0; i < pointsScaledAndShifted.length; i++) {
				const mask = pointsScaledAndShifted[ i ];
				context.fillStyle = (hoveredMaskId === masks[ i ].id)
					? updateAlphaInHsla(localColors[ i ], 1)
					: localColors[ i ];
				context.beginPath();
				context.moveTo(mask[ 0 ].x, mask[ 0 ].y);

				for(let j = 1; j < mask.length; j++) {
					context.lineTo(mask[ j ].x, mask[ j ].y);
				}

				context.closePath();
				context.fill();

				if(context.isPointInPath(mouseX, mouseY)) {
					// console.log(identifications.find(i => i.id === masks[ 0 ].id));
					setHoveredMaskId(mask[ 0 ].id);
				}
			}
		}
	}, [ colors, height, hoveredMaskId, image, masks, transparency, width ]);

	useEffect(() => {
		setColors(
			[ ...colors.map(color => updateAlphaInHsla(color, transparency)) ]
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ transparency ]);

	const handleMouseMove = useCallback((event: any) => {
		const { offsetX, offsetY } = event;
		drawImage(offsetX, offsetY);
	}, [ drawImage ]);

	const handleClick = useCallback((event: any) => {
		if(hoveredMaskId) {
			console.log(identifications.find(i => i.id === hoveredMaskId));
			onClickedMask(hoveredMaskId);
		}
	}, [ hoveredMaskId, identifications, onClickedMask ]);

	useEffect(() => {
		const current = ref.current;
		current?.addEventListener("mousemove", handleMouseMove);
		current?.addEventListener("click", handleClick);

		return () => {
			current?.removeEventListener("mousemove", handleMouseMove);
			current?.removeEventListener("click", handleClick);
		};
	}, [ handleClick, handleMouseMove ]);

	useEffect(() => {
		if(image) {
			if(image.complete) {
				// If the image is already loaded, draw it immediately
				drawImage(0, 0);
			}
			else {
				// Listen for when the image is fully loaded, then draw it
				image.onload = () => {
					drawImage(0, 0);
				};
			}
		}
	}, [ image, drawImage ]);

	return (
		<canvas ref={ref} width={width} height={height} />
	);
};

interface RLE {
	counts: string;
	size: Array<number>;
}

interface Point {
	x: number;
	y: number;
}

const douglasPeucker = (points: Point[], epsilon: number): Point[] => {
	if(points.length < 3) return points;

	const dMax = (points: Point[], start: number, end: number): { index: number, dist: number; } => {
		let index = -1;
		let maxDist = 0;
		const startPt = points[ start ];
		const endPt = points[ end ];

		for(let i = start + 1; i < end; i++) {
			const pt = points[ i ];
			const dist = perpendicularDistance(pt, startPt, endPt);
			if(dist > maxDist) {
				index = i;
				maxDist = dist;
			}
		}
		return { index, dist: maxDist };
	};

	const perpendicularDistance = (point: Point, start: Point, end: Point): number => {
		const num = Math.abs((end.y - start.y) * point.x - (end.x - start.x) * point.y + end.x * start.y - end.y * start.x);
		const denom = Math.sqrt((end.y - start.y) ** 2 + (end.x - start.x) ** 2);
		return num / denom;
	};

	const simplify = (points: Point[], start: number, end: number, epsilon: number): Point[] => {
		const { index, dist } = dMax(points, start, end);

		if(dist > epsilon) {
			const left = simplify(points, start, index, epsilon);
			const right = simplify(points, index, end, epsilon);
			return [ ...left.slice(0, left.length - 1), ...right ];
		}
		else {
			return [ points[ start ], points[ end ] ];
		}
	};

	return simplify(points, 0, points.length - 1, epsilon);
};